import React, { Component, useRef } from "react";

import axios from '../utils/axios';
import NotificationAlert from "react-notification-alert";
import Chatbox from "./ChatBox";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Form,
  Tab,
  ToastContainer,
  Toast,
  Tabs,
  Table,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { CardBody } from "reactstrap";
import "./chatchild.css";

class ChatChild extends Component {

    constructor(props) {

        super(props);

        this.state = {
            showNotifications: false,
            showPhotoSelectorModal: false,
            uploadingPhoto: false,
            notifications: [],
            username: "",
            profile_image: "",
            photo: null,
            age: "",
            state: "",
            city: "",
            chatlist: [],
            open_chatlist: []
        }

        this.fetchUserDetails();
        this.fetchChatList();

        this.updateLastSeen();

        this.notificationAlertRef = React.createRef();
        this.childChatRefs = [];

        this.bindSocket();

        var _this = this;

        setInterval(function() {
            if(_this.props.data.is_online) {
                _this.updateLastSeen();
                _this.fetchChatList();
            }
            
        }, 20000);

    }

    addChildRef = (ref) => {
        if (ref) {
          this.childChatRefs.push(ref);
        }
    }

    passTypingEventToChild = (dt) => {
        this.childChatRefs.forEach((childRef) => {
          childRef.recieveTyping(dt);
        });
    }

    passMessageToChild = (dt) => {
        this.childChatRefs.forEach((childRef) => {
          childRef.newMessageTrigger(dt);
        });
    }
    
    bindSocket() {
        if(typeof this.props.user_id!="undefined") {

          var _this = this;

          this.props.socket.on("chat_message_"+this.props.user_id, (dt)=> {
            _this.passMessageToChild(dt);
            _this.fetchChatList();
          });
    
          this.props.socket.on("typing_event_"+this.props.user_id, (dt)=> {
            _this.passTypingEventToChild(dt);
          });
        } else {
            var _this = this;
            setTimeout(function() {
                _this.bindSocket();
            }, 500);
        }
    }

    removeUser() {
        this.props.removeUserFromTabs(this.props.user_id);
    }

    deleteUser() {
        var f = confirm("Are you sure?");
        if(f) {
            axios.api_post("delete_account", {
                user_id: this.props.user_id
            }, rsp=> {
                this.props.removeUserFromTabs(this.props.user_id);
            });
        }
    }

    updateUnreadCount() {
        var unread = 0;
        this.state.chatlist.forEach((dts, i) => {
            if (dts.last_message_read_status == "0" && dts.last_message_from_user!=this.props.user_id) {
                unread++;
            }
        });

        this.props.updateUnreadCount(this.props.user_id, unread);
    }

    updateLastSeen() {
        axios.api_post("update_last_seen", {
            user_id: this.props.user_id
        }, rsp=> {

        });
    }

    openChat(user_id) {
        var open_chats = this.state.open_chatlist;

        var found_index = -1;

        open_chats.forEach((dt, i) => {
            if (dt == user_id) {
                found_index = i;
            }
        });

        var main_found_index = -1;
        this.state.chatlist.forEach((dts, i) => {
            if (dts.user_id == user_id) {
                main_found_index = i;
            }
        });

        if(main_found_index > -1) {
            var chat_list = this.state.chatlist;
            chat_list[main_found_index].messageunread = 0;
            this.setState({
                chatlist: chat_list
            })
        }
        

        if (found_index < 0) {
            open_chats.push(user_id);

            this.setState({
                open_chatlist: open_chats,
            });
        }

        this.updateUnreadCount();
    }

    closeChatWindow(user_id) {
        const open_chats = [...this.state.open_chatlist];
    
        const indexToRemove = open_chats.indexOf(user_id);
    
        if (indexToRemove !== -1) {
          open_chats.splice(indexToRemove, 1);
        }
    
        this.setState({ open_chatlist: open_chats });

        this.fetchChatList();
    }

    showAlert(message) {
    
        var options = {};
        options = {
        place: 'tr',
        message: (
            <div>
            <div>
                {message}
            </div>
            </div>
        ),
        type: "success",
        icon: "nc-icon nc-bell-55",
        autoDismiss: 2,
        };
        this.notificationAlertRef.current.notificationAlert(options);
    }

    fetchChatList() {

        var _this = this;

        axios.api_post("get_user_chat_list", {
            user_id: this.props.user_id
        }, rsp=> {

            this.setState({
                chatlist: rsp.data.data
            });

            setTimeout(function() {
                _this.updateUnreadCount();
            }, 1000);
        });
    }

    renderUser(dt) {
        return (
            <Card className="userCard">
                <CardBody>
                    <span>
                        {dt.username}, {dt.away_status} ({dt.credits=="" ? 0 : dt.credits})
                        &nbsp;
                        {
                            (dt.last_screen=="dashboard" || dt.last_screen=="") &&
                            (
                                <i className="fa fa-home"></i>
                            )
                        }
                        {
                            dt.last_screen=="view_profile" &&
                            (
                                <i className="fa fa-user"></i>
                            )
                        }
                        {
                            dt.last_screen=="chat" &&
                            (
                                <i className="fa fa-comment"></i>
                            )
                        }
                        {
                            dt.last_screen=="notification" &&
                            (
                                <i className="fa fa-bell"></i>
                            )
                        }
                        {
                            dt.last_screen=="photos" &&
                            (
                                <i className="fa fa-image"></i>
                            )
                        }
                    </span>
                    <div className="float-right">
                        <a className="btn-simple btn-icons" onClick={()=> this.sendView(dt.id)}>
                            <i className="fa fa-eye"></i>
                        </a>
                        <a className="btn-simple btn-icons" onClick={()=> this.sendActivity(dt.id, "crush")}>
                            <i className="fa fa-heart"></i>
                        </a>
                        <a className="btn-simple btn-icons" onClick={()=> this.sendActivity(dt.id, "favourite")}>
                            <i className="fa fa-star"></i>
                        </a>
                        <a className="btn-simple btn-icons" onClick={()=> this.openChat(dt.id)}>
                            <i className="fa fa-comment"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>
        )
    }

    sendFreeViewAll() {
        axios.api_post("send_view_to_all", {
            to_users: JSON.stringify(this.props.free_users),
            from_user: this.props.user_id
        }, rsp=> {
            this.showAlert("View sent");
        });
    }

    sendPaidViewAll() {
        
        axios.api_post("send_view_to_all", {
            to_users: JSON.stringify(this.props.paid_users),
            from_user: this.props.user_id
        }, rsp=> {
            this.showAlert("View sent");
        });
    }

    sendActivity(to_user, type) {

        axios.api_post("send_activity", {
            from_user: this.props.user_id,
            to_user: to_user,
            type: type
        }, rsp=> {
            
        });

    }

    sendView(to_user) {
        axios.api_post("send_view", {
            from_user: this.props.user_id,
            to_user: to_user
        }, rsp=> {
            this.showAlert("View sent");
        });
    }

    handleChange = (e) => {
        const photo = e.target.files[0];
    
        // Check if the selected file's type is allowed
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (photo && allowedTypes.includes(photo.type)) {
          this.setState({
            photo
          });
        } else {
          alert('Please select a PNG, JPG, or JPEG file.');
          e.target.value = null; // Clear the input
        }
    };

    deleteUserPhoto() {
        axios.api_post("delete_profile_image", {
            user_id: this.props.user_id
        }, rsp=> {
            this.fetchUserDetails();
        });
    }

    async savePhotoUpload() {

        const formData = new FormData();
        formData.append('photo', this.state.photo);
        formData.append('user_id', this.props.user_id);

        if(this.state.photo) {
            this.setState({
                uploadingPhoto: true
            });

            axios({
                method: 'post',
                url: axios.getBaseUrl()+"upload_user_photo",
                data: formData,
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer '+localStorage.getItem('accessToken'),
                }
            }).then(response => {

                this.setState({
                    uploadingPhoto: false,
                    showPhotoSelectorModal: false
                });

                this.fetchUserDetails();
            });
        } else {
            alert("No photo selected");
        }
  
    }

    fetchUserDetails() {
        axios.api_post("get_currentuser_profile", {
            user_id: this.props.user_id
        }, rsp=> {
            this.setState({
                username: rsp.data.data.username,
                profile_image: rsp.data.data.profile_image,
                age: rsp.data.data.age,
                state: rsp.data.data.state,
                city: rsp.data.data.city,
            })
        });
    }

    openPhotoSelector() {
        this.setState({
            showPhotoSelectorModal: true
        })
    }

    toggleStatus() {
        this.props.toggleStatus(this.props.user_id);
    }

    render() {
        return (
            <>
            <div className="rna-container">
                <NotificationAlert ref={this.notificationAlertRef} />
            </div>
            <Modal
                className="modal-primary"
                show={this.state.showPhotoSelectorModal}
                onHide={() => this.setState({
                    showPhotoSelectorModal: false
                })}
                >
                <Modal.Header className="justify-content-center">
                    <span>Change Profile Photo</span>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Form>
                        <div class="form-group">
                            <label for="city">Choose Photo</label>
                            <input
                                type="file"
                                class="form-control"
                                id="photo"
                                name="photo"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleChange}
                                placeholder="Choose Photo"
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                    className="btn-simple"
                    type="button"
                    onClick={() => this.setState({
                        showPhotoSelectorModal: false
                    })}>
                        Close
                    </Button>

                    <Button
                    className="btn-danger"
                    type="button"
                    onClick={()=> this.deleteUserPhoto()}>
                        Delete Photo
                    </Button>

                    <Button
                    className="btn-primary"
                    type="button"
                    disabled={this.state.uploadingPhoto}
                    onClick={()=> this.savePhotoUpload()}>
                        {this.state.uploadingPhoto==false ? "Upload" : "Uploading..."}
                    </Button>
                    
                </Modal.Footer>
            </Modal>
            <Container fluid>
                <div className="row">
                    <div className="col-md-9">
                        <div className="top_buttons_holder">
                            <a className="btn btn-warning" onClick={()=> this.removeUser()}>Remove Profile</a>
                            <a className="btn btn-danger" onClick={()=> this.deleteUser()}>Delete Profile</a>
                        </div>
                        <Card>
                            <CardBody>
                                <div>
                                    <label>
                                        <input type="checkbox" checked={this.props.data.is_online} onClick={()=> this.toggleStatus()} />
                                        <span style={{"marginLeft": "10px"}}>Online</span>
                                    </label>
                                </div>
                                <div className="profile_image" onClick={()=> this.openPhotoSelector()}>
                                    <img
                                        src={this.state.profile_image}
                                    />
                                </div>
                                <h5>{this.state.username} ({this.props.user_id}), {this.state.age}</h5>
                                <a href="javascript:void(0)" className="alert-icon"><i className="fa fa-bell"></i></a>
                                <div
                                    className={'dropdown-menu ' + (this.state.showNotifications==true ? ' showAlert' : 'hideAlert')}
                                    id="notification-list"
                                    aria-labelledby="notificationDropdown"
                                    >
                                    {this.state.notifications.map((nt, key) => {
                                        return (
                                            <div
                                                className="rows notification-rows"
                                                key={key}
                                            >
                                                <div className="col-sm-3">
                                                    <div className="circle-image">
                                                        <img
                                                        src={nt.profile_image}
                                                        alt="your_alt_text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <div>
                                                        {nt.username} {nt.msg}
                                                    </div>
                                                    <small>{nt.time}</small>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    {this.state.notifications.length==0 &&
                                        <div className="no_notification_text">No Notifications</div>
                                    }
                                    </div>
                                <div>
                                    {this.state.state}, {this.state.city}
                                </div>
                            </CardBody>
                        </Card>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="grid_header">
                                    <span>Free Users</span>
                                    <div className="float-right">
                                        <a className="btn-simple btn-icons btn-white" onClick={()=> this.sendFreeViewAll()}>
                                            <i className="fa fa-eye"></i>
                                        </a>
                                        <a className="btn-simple btn-icons btn-white">
                                            <i className="fa fa-heart"></i>
                                        </a>
                                        <a className="btn-simple btn-icons btn-white">
                                            <i className="fa fa-star"></i>
                                        </a>
                                        <a className="btn-simple btn-icons btn-white">
                                            <i className="fa fa-comment"></i>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    {this.props.free_users.map((dt, i)=> {
                                        return this.renderUser(dt)
                                    })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="grid_header">
                                    <span>Paid Users</span>
                                    <div className="float-right">
                                        <a className="btn-simple btn-icons btn-white" onClick={()=> this.sendPaidViewAll()}>
                                            <i className="fa fa-eye"></i>
                                        </a>
                                        <a className="btn-simple btn-icons btn-white">
                                            <i className="fa fa-heart"></i>
                                        </a>
                                        <a className="btn-simple btn-icons btn-white">
                                            <i className="fa fa-star"></i>
                                        </a>
                                        <a className="btn-simple btn-icons btn-white">
                                            <i className="fa fa-comment"></i>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    {this.props.paid_users.map((dt, i)=> {
                                        return this.renderUser(dt)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="grid_header">
                            <span>Chat List</span>
                        </div>
                        <ul className="chat_thread_list">
                            {this.state.chatlist.map((lst, idx)=> {
                                return (
                                    <li onClick={()=> this.openChat(lst.user_id)} className={(lst.last_message_read_status=="0" && lst.last_message_from_user!=this.props.user_id) ? "unread_chat": "read_chat"}>
                                        {lst.username}
                                        {lst.replied_by_bothside=="1" &&
                                            <i class="fa fa-check-circle float-right"></i>
                                        }
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </div>
                <div id="chat_holder">
                {
                    <>
                    {this.state.open_chatlist.map((user_id, idx) => {
                        return (
                        <>
                            <Chatbox
                            socket={this.props.socket}
                            closeChatWindow={(id) => this.closeChatWindow(id)}
                            from_user={this.props.user_id}
                            user_id={user_id}
                            ref={this.addChildRef}
                            key={user_id || idx}
                            />
                        </>
                        );
                    })}
                    </>
                }
                </div>
            </Container>
            </>
        )
    }
}

export default ChatChild;