import React, { Component } from "react";

import axios from '../utils/axios';
import ChatChild from "./ChatChild";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Tab,
  Tabs,
  Form,
  Table,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import "./chatbox.css";
import Socket from '../utils/socket';

class Chat extends Component {

    constructor(props) {

        super(props);

        this.state = {
            addingUser: false,
            showUsersModal: false,
            showCreateUserModal: false,
            selectedUserIndex: 0,
            socket: new Socket("https://amoure.in:4004"),
            form: {
                gender: "F",
                username: "",
                age: "30",
                marital_status: "married",
                city: "",
            },
            free_users: [],
            paid_users: [],
            userlist: [],
            chat_users: []
        }

        this.fetchFreeUsers();
        this.fetchPaidUsers();

        var _THIS = this;
        setTimeout(function() {
            _THIS.loadChatUsers();
        }, 1000);

        setInterval(function() {
            _THIS.fetchPaidUsers();
            _THIS.fetchFreeUsers();
        }, 20000);

    }

    showCreateUserModalFunc() {
        this.setState((prevState)=> ({
            showCreateUserModal: true,
            form: {
                ...prevState.form,
                age: Math.floor(Math.random() * (35 - 18) + 18)
            }
        }));
    }

    loadChatUsers() {
        if(localStorage.getItem("chat_users")) {
            this.setState({
                chat_users: JSON.parse(localStorage.getItem("chat_users"))
            });
        }
    }

    fetchFreeUsers() {
        axios.api_post("discover_users", {
            last_id: "0",
            user_type: "free"
        }, rsp=> {
            this.setState({
                free_users: rsp.data.fetched_users
            });
        });
    }

    fetchPaidUsers() {
        axios.api_post("discover_users", {
            last_id: "0",
            user_type: "paid"
        }, rsp=> {
            this.setState({
                paid_users: rsp.data.fetched_users
            });
        });
    }

    fetchUsers() {
        axios.api_post("get_chat_list_fake_users", {}, rsp=> {
            this.setState({
                userlist: rsp.data.users
            });
        });
    }

    addUser() {

        this.fetchUsers();

        this.setState({
            showUsersModal: true
        });
    }

    selectUserFromList(indx) {
        this.setState({
            selectedUserIndex: indx
        });
    }

    saveChatUserList() {
        localStorage.setItem("chat_users", JSON.stringify(this.state.chat_users));
    }

    addUserToTab() {

        var chat_users = this.state.chat_users;

        var found = false;

        chat_users.forEach((chtl)=> {
            if(chtl.id==this.state.userlist[this.state.selectedUserIndex].id) {
                found = true;
            }
        });

        if(found==false) {

            chat_users.push({
                id: this.state.userlist[this.state.selectedUserIndex].id,
                is_online: true,
                unread_count: 0,
                username: this.state.userlist[this.state.selectedUserIndex].username
            })

            this.setState({
                chat_users: chat_users
            });

            this.saveChatUserList();
        }

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            [name]: value,
          },
        }));
    };
    
    handleGenderChange = (name, value) => {
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            [name]: value,
          },
        }));
    };

    getRandomAge() {
        return 30;
    }

    toggleStatus(user_id) {
        var chat_users = this.state.chat_users;
    
        chat_users.forEach((dt, i)=> {
            if(dt.id==user_id) {
                chat_users[i].is_online = !chat_users[i].is_online;
            }
        });
    
        this.setState({ chat_users: chat_users });

        this.saveChatUserList();
    }

    removeUserFromTabs(user_id) {

        var chat_users = this.state.chat_users;
    
        chat_users.forEach((dt, i)=> {
            if(dt.id==user_id) {
                chat_users.splice(i, 1);
            }
        });
    
        this.setState({ chat_users: chat_users });

        this.saveChatUserList();
    }

    updateUnreadCount(user_id, unread_count) {

        var chat_users = this.state.chat_users;
    
        chat_users.forEach((dt, i)=> {
            if(dt.id==user_id) {
                chat_users[i].unread_count = unread_count;
            }
        });
    
        this.setState({ chat_users: chat_users });

        this.saveChatUserList();
    }

    clearUsers = () => {
        localStorage.removeItem("chat_users");
        window.location.reload();
    }

    generateUsername = () => {
        axios.api_post("generate_random_username", {

        }, rsp=> {
            this.setState((prevState)=> ({
                showCreateUserModal: true,
                form: {
                    ...prevState.form,
                    username: rsp.data.username
                }
            }));
        });
    }

    saveCompleteProfile = () => {

        var form = this.state.form;
        var status = false;
        var message = "";

        if(form.username.length < 3) {
            message = "Username must be more than 3 characters";
        } else if(form.age.toString().length!=2 || form.age < 18) {
            message = "Enter age more than 18";
        } else if(form.city.length==0) {
            message = "Enter city";
        } else {
            status = true;
        }

        if(!status) {
            alert(message);
        } else {

            this.setState({
                addingUser: true
            });

            axios.api_post("create_user", {
                username: form.username,
                age: form.age,
                city: form.city,
                marital_status: form.marital_status
            }, rsp=> {

                if(rsp.data.status) {
                    this.setState({
                        showCreateUserModal: false,
                        form: {
                            gender: "F",
                            username: "",
                            age: "30",
                            marital_status: "married",
                            city: "",
                        }
                    });

                    this.addUser();
                } else {
                    alert(rsp.data.message);
                }

                this.setState({
                    addingUser: false
                });

            })
        }

    }

    render() {return (
        <>
            <Modal
                className="modal-primary"
                show={this.state.showCreateUserModal}
                onHide={() => this.setState({
                    showCreateUserModal: false
                })}
                >
                <Modal.Header className="justify-content-center">
                    <span>Create New User</span>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Form>
                        <div class="form-group">
                            <label for="username">Username</label>
                            <div class="container mt-1">
                                <div class="row">
                                    <div class="col">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="username"
                                            name="username"
                                            value={this.state.form.username}
                                            onChange={(e) => this.handleChange(e)}
                                            placeholder="Enter username"
                                         />
                                    </div>
                                    <div class="col-auto">
                                        <button type="button" class="btn btn-primary" onClick={()=> this.generateUsername()}>Generate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="container mt-1">
                                <div class="row">
                                    <div class="col">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="age"
                                            value={this.state.form.age}
                                            onChange={(e) => this.handleChange(e)}
                                            id="age"
                                            placeholder="Enter age"
                                        />
                                    </div>
                                    <div class="col">
                                    <select
                                class="form-control"
                                name="marital_status"
                                id="marital_status"
                                value={this.state.form.marital_status}
                                onChange={(e) => this.handleChange(e)}
                            >
                                <option value={"married"}>Married</option>
                                <option value={"single"}>Single</option>
                                <option value={"divorced"}>Divorced</option>
                            </select>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                        <label for="city">City</label>
                        <div class="container mt-1">
                                <div class="row">
                                    <div class="col">
                            
                            <input
                                type="text"
                                class="form-control"
                                id="city"
                                name="city"
                                onChange={(e) => this.handleChange(e)}
                                placeholder="Enter city"
                            />
                            </div>
                            </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                    className="btn-simple"
                    type="button"
                    onClick={() => this.setState({
                        showCreateUserModal: false
                    })}>
                        Close
                    </Button>

                    <Button
                    className="btn-primary"
                    type="button"
                    disabled={this.state.addingUser}
                    onClick={()=> this.saveCompleteProfile()}>
                        {this.state.addingUser==false ? "Add User" : "Saving..."}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Container fluid>

                <Modal
                    className="modal-mini modal-primary"
                    show={this.state.showUsersModal}
                    onHide={() => this.setState({
                        showUsersModal: false
                    })}
                    >
                    <Modal.Header className="justify-content-center">
                        <span>Users List</span>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <select className="form-control" onChange={(e)=>this.selectUserFromList(e.target.value)}>
                            {this.state.userlist.map((ctu, key) => {
                                return (
                                    <option value={key}>{ctu.username}</option>
                                )
                            })}
                        </select>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                        className="btn-simple"
                        type="button"
                        onClick={() => this.setState({
                            showUsersModal: false
                        })}
                        >Close</Button>
                        <Button
                        className="btn-primary"
                        type="button"
                        onClick={()=> this.addUserToTab()}
                        >Add User</Button>
                        
                    </div>
                </Modal>
                <div style={{"marginBottom": "10px", "clear": "both", "height": "50px"}}>

                    <Button className="btn-fill mr-1"
                        type="submit"
                        variant="success" onClick={()=> this.addUser()}>+ Add User</Button>

                    <Button className="btn-fill mr-1"
                        type="submit"
                        variant="danger" onClick={()=> this.clearUsers()}>- Clear Users</Button>

                    <Button className="btn-fill float-right"
                        type="submit"
                        variant="info" onClick={()=> this.showCreateUserModalFunc()}>+ Create New User</Button>

                </div>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    {this.state.chat_users.map((ctu, key) => {
                        if(ctu) {
                            return (
                                <Tab eventKey={ctu.id} title={ctu.username+(ctu.unread_count > 0 ? " ("+ctu.unread_count+")":"")} tabClassName={ctu.unread_count > 0 ? 'blink_tab' : (ctu.is_online ? 'onlineTab' : 'awayTab')}>
                                    <ChatChild toggleStatus={(id)=> this.toggleStatus(id)} removeUserFromTabs={(id)=> this.removeUserFromTabs(id)} updateUnreadCount={(id, unread)=> this.updateUnreadCount(id, unread)} data={ctu} socket={this.state.socket} user_id={ctu.id} free_users={this.state.free_users} paid_users={this.state.paid_users}></ChatChild>
                                </Tab>
                            )
                        }
                        
                    })}
                </Tabs>
            </Container>
        </>
    )}
}

export default Chat;
