import axios from 'axios';

import { useHistory } from 'react-router-dom';



const instance = axios.create({
	baseURL: 'https://amoure.in/backend/apiChatAgents/',
	headers: {
        common: {
            'Content-Type': 'multipart/form-data',
        },
    },
	
});

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance.getBaseUrl = function() {
	return "https://amoure.in/backend/apiChatAgents/";
};

instance.api_post = function(path, params, callback, history) {

    var token = "";

    if(localStorage.getItem('accessToken')) {
        token = localStorage.getItem('accessToken');
    }

	var frm = new FormData();

	Object.keys(params).forEach(function(key) {
		frm.append(key, params[key]);
	});

	if(global.userID) {
		frm.append("user_id", global.userID);
	}

	axios({
	    method: 'post',
	    url: this.getBaseUrl()+path,
	    data: frm,
	    // withCredentials: true,
	    // credentials: 'include',
	    headers: {
	    	'Content-Type': 'multipart/form-data',
	    	// "Content-Type": "application/x-www-form-urlencoded",
	    	Authorization: 'Bearer '+token,
	    },
		validateStatus: function (status) {
			if(status==401) {
				if(history) {
					history.push('/login');
				}
			} else {
				return true;
			}
		}
    }).then(response => {

  		callback(response);
	});

}


export default instance;