// src/components/Login.js
import React, { useState } from 'react';
import { Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import axios from '../utils/axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = (e) => {
    e.preventDefault();

    axios.api_post("login_user", {
        username: username,
        password: password
    }, rsp=> {
      if(rsp.data.status) {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('accessToken', rsp.data.token);
        history.push('/admin/chats');
      } else {
        alert('Invalid credentials');
      }
    });

    // Here you should add your authentication logic
    // if (username === 'admin' && password === 'admin') {
    //   localStorage.setItem('isAuthenticated', 'true');
    //   history.push('/admin/dashboard');
    // } else {
    //   alert('Invalid credentials');
    // }
  };

  return (
    <Container fluid>
        <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Col md={4}>
            <Card>
              <Card.Header>
                <Card.Title className='center' as="h2">Login</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleLogin}>
                  <Form.Group>
                    <label>Username</label>
                    <Form.Control
                      placeholder="Username"
                      value={username}
                      onChange={(e)=> setUsername(e.target.value)}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <label>Password</label>
                    <Form.Control
                      placeholder="Password"
                      value={password}
                      onChange={(e)=> setPassword(e.target.value)}
                      type="password"
                    ></Form.Control>
                  </Form.Group>
                  <br/>
                  <Button className="btn-fill pull-right" type="submit" variant="success">
                    Login
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
  );
};

export default Login;