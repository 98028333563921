import io from 'socket.io-client';

class Socket {
  constructor(url) {
    this.socket = io(url, {
        transports: ['websocket'],
        autoConnect: true,
    });
    this.listeners = {};
    this.socket.on('connect', () => {
      console.log('Connected to socket');
    });
  }

  on(eventName, handler) {
    this.listeners[eventName] = handler;
    this.socket.on(eventName, data => {
      this.listeners[eventName](data);
    });
  }

  off(eventName) {
    delete this.listeners[eventName];
    this.socket.off(eventName);
  }

  emit(eventName, data) {
    this.socket.emit(eventName, data);
  }

  disconnect() {
    this.socket.disconnect();
    console.log('Disconnected from socket');
  }
}

export default Socket;